<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.templates.title') }}</div>
        <small>{{ $t('page.settings.templates.description') }}</small>
      </div>
    </div>
    <template v-if="selected.length">
      <div class="card-header flex justify-between items-start">
        <div>
          <div class="font-semibold">{{ $t('page.templates.sentence.your_templates') }}</div>
          <small class="text-gray-500"
            v-html="$t('page.templates.sentence.your_templates_description-html')"
          />
        </div>
        <base-popover>
          <template v-slot:button>
            <i class="far fa-question-circle text-blue-600"></i>
          </template>
          <div
            class="popover-body"
            v-html="$t('page.templates.helper-html')"
          />
        </base-popover>
      </div>
      <div class="card-body flex flex-col space-y-6">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 mt-2">
          <template-thumbnail
            v-for="template in selected"
            :key="template.id"
            :template="template"
            hide-selection
            @onToogle="toogle(template.id)"
          />
        </div>
      </div>
    </template>

    <div class="card-header mt-4">
      <div>
        <div class="font-semibold">{{ $t('page.templates.sentence.all_templates') }}</div>
      </div>
      <base-popover v-if="!selected.length">
        <template v-slot:button>
          <i class="far fa-question-circle text-blue-600"></i>
        </template>
        <div
          class="popover-body"
          v-html="$t('page.analytics.audience_age.helper-html')"
        />
      </base-popover>
    </div>
    <div class="card-body">
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 mt-2"
        v-if="isLoaded"
      >
        <template-thumbnail
          v-for="template in templates"
          :key="template.id"
          :template="template"
          @onToogle="toogle(template.id)"
        />
      </div>

      <div ref="listsEnd"></div>
      <div
        class="flex justify-center items-center space-x-2 w-full my-16"
        v-if="isLoading"
      >
        <i class="fas fa-spinner fa-spin"></i>
        <div>{{ $t('sentence.loading') }}</div>
      </div>
    </div>

    <div class="card-footer flex justify-center w-full">
      <router-link
        :to="{ name: 'settings-templates' }"
        class="btn btn-primary btn-lg flex flex-col w-max"
      >
        Ver todos os templates
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useTemplates } from '@/composables/templates/useTemplates';
import TemplateThumbnail from '@/components/templates/templateThumbnail.vue'
import BasePopover from '@/components/base/BasePopover.vue'

export default defineComponent({
  components: {
    TemplateThumbnail,
    BasePopover
  },

  setup () {
    const {
      isLoaded,
      isLoading,
      templates,
      selected,
      fetchTemplates,
      toogle
    } = useTemplates()

    onMounted(() => {
      if (!templates.value.length) {
        fetchTemplates({
          skip: 0
        })
      }
    })

    return {
      isLoaded,
      isLoading,
      templates,
      selected,
      toogle
    }
  }
})
</script>

<style scoped>
*:hover {
  text-decoration: none !important;
}
</style>
